<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Service Dashboard</h1>
    </div>
    <v-row justify="center" align="stretch">
      <v-col cols="12" md="4">
        <v-card class="pa-3 text-center">
          <v-card-title>Hours This Month</v-card-title>
          <v-card-text style="overflow-y: auto; height:300px" >
            <v-progress-circular
              :value="serviceHoursPercent"
              :width="4"
              color="green"
              :size="$vuetify.breakpoint.mdAndUp ? '200' : '115'"
              rotate="-90"

            >
              <div class="text-h5">{{ serviceHours }} / {{requiredHours}}</div>

            </v-progress-circular>

          </v-card-text>


        </v-card>
      </v-col>

<v-col cols="12" md="4">

      <v-card class="pa-3 text-center">
        <v-card-title>Service Breakdown</v-card-title>
        <div style="position:relative;height:300px; width:400px;" class="justify-center">
          <apexcharts v-if="ready" type="pie" :options="chartOptions" :series="series" ref="chart"></apexcharts>
        </div>
      </v-card>
</v-col>



      <v-col cols="12" md="4" >
        <v-card class="pa-3 text-center " height="100%" width="100%"   >
          <v-card-title>Total Hours</v-card-title>
          <v-card-text class="pa-10">
            <span class="text-h1">{{totalHours}}</span>
          </v-card-text>
        </v-card>

      </v-col>


    </v-row>

    <v-row>
      <v-col cols="12">
        <leaderboard :students="leaderboard"></leaderboard>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-divider class="my-2"></v-divider>
        <h1 class="text-h5">{{currentMonthName}} Service</h1>
        <v-divider class="my-2"></v-divider>
      </v-col>
    </v-row>

    <v-row v-if="service.length===0">
      <v-col cols="12" >
        No service found for {{currentMonthName}}
      </v-col>
    </v-row>

    <v-row v-if="service.length>0">
      <v-col cols="12" >
        <v-card>
          <service-list :service="service"></service-list>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>

import httpClient from "@/plugins/axios";
import axios from 'axios';
import VueApexCharts from 'vue-apexcharts'
import {mapGetters} from 'vuex';
import ServiceList from "@/components/ingram/service/ServiceList";
import Leaderboard from '@/components/ingram/service/Leaderboard';

export default {
  name: "StudentDashboard",
  components: {apexcharts: VueApexCharts, ServiceList, Leaderboard},
  data(){
    return{
      ready:false,
      serviceHours: 0,
      requiredHours: 0,
      totalHours: 0,
      service:[],
      series:[],
      leaderboard: [],

      chartOptions:{
        legend:{
          position: 'right',
//          horizontalAlign:'right',

        },
        plotOptions:{
          pie:{
            customScale: 1,
          }
        },
        //cutoutPercentage: 65,
        chart:{
          redrawOnParentResize: false,

          width: '300'

        },
        labels:[],
      },
    }
  },
  computed:{
    ...mapGetters({
      user: 'app/getUser'
    }),
    serviceHoursPercent(){
      const complete = (this.serviceHours / this.requiredHours) * 100;
      return complete > 100 ? 100 : complete;
    },
    currentMonthName(){
      return new Date().toLocaleString('default', { month: 'long' });
    }
  },
  methods:{
    refresh(){
      const vm = this;

      const currentSize = this.$vuetify.breakpoint.name;

      const lb = httpClient.get('/reports/leaderboard');
      const sv = httpClient.get(`/service/service/${vm.user.identifier}`);

      axios.all([lb, sv])
        .then(axios.spread((lbResponse, svResponse) => {
          vm.service = svResponse.data.service;
          vm.serviceHours = svResponse.data.hoursThisMonth;
          vm.requiredHours = svResponse.data.hoursRequiredThisMonth;
          vm.totalHours = svResponse.data.hoursThisYear;

          const data = svResponse.data.chartData;

          const series = data.map(x => x.hours);
          const labels = data.map(x => x.category);


          vm.series = series;
          vm.chartOptions.labels = labels;

          if(currentSize === 'xs' || currentSize==='sm'){
            vm.chartOptions.legend.position = 'bottom';
          }

          vm.leaderboard = lbResponse.data.slice(0, 5);

          this.$nextTick(function () {vm.ready = true;});
        }))
        .catch(error =>{
          console.log(error);
        });
    }
  },
  mounted(){
    this.refresh();
  },
  updated(){
    //this.refresh();
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
/*
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .apexcharts-legend{
    justify-content: center !important;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .apexcharts-legend{
    text-align: left;
  }
}
*/


.pie:hover {
  box-shadow: 3px 3px 3px 3px rgb(177, 175, 175);
  transition: all 0.4s ease-out;
}
.full-height {
  height: 100%;
}
</style>
