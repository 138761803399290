<template>
  <div>
    <v-card v-if="leaders.length > 0">
      <v-card-title>
        Leaderboard
      </v-card-title>
      <v-card-text>
        <table style="border-collapse: collapse;">
          <thead>
            <tr>
              <th style="width:75px;">Rank</th>
              <th style="width:400px;">Name</th>
              <th>Hours This Month</th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(s, idx) in leaders" :key="idx">
            <td>{{idx + 1}}</td>
            <td>{{s.firstName}} {{s.lastName}}

              <v-chip
                v-if="s.isCurrentUser"
                class="ma-2"
                color="#4BAF4F"
                label
                text-color="white"
              >
                <v-icon left>
                  mdi-label
                </v-icon>
                You Are Here!
              </v-chip>
            </td>
            <td>{{s.hours}}</td>
          </tr>
          </tbody>
        </table>

      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="toggleLeaderboard"
        >{{buttonText}}</v-btn>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>
export default {
  name: "Leaderboard",
  props:['students'],
  data(){
    return {
      leaders: [],
      lessCount: 5,
      showingAll: false
    }
  },
  computed:{
    buttonText(){
      return this.showingAll ? 'Show Less' : 'Show More';
    }
  },
  methods:{
    toggleLeaderboard(){
      this.showingAll = !this.showingAll;

      if(this.showingAll){
        this.leaders = this.students;
      }else{
        this.leaders = this.students.slice(0,this.lessCount);
      }
    }
  },
  watch:{
    students: function(val){
      console.log('watch')
      const json = JSON.stringify(val);
      const less = JSON.parse(json);
      this.leaders = less.splice(0,this.lessCount);
    }
  }
}
</script>

<style scoped>
  table{
    font-size:16px;
  }
  thead tr{
    border-bottom: solid 1px #A83540;
    text-align: left;
  }

  tbody tr td{
    padding-top:10px;

  }
</style>
